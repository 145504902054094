import * as React from "react";
import { useState } from "react";
import styled from "@emotion/styled";
import tw from "twin.macro";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";

const Page = tw.div`
    container mx-auto
`;
const PageContainer = tw.div`
    md:flex justify-center px-6 my-12 bg-white mr-8
`;

const Form = tw.form`w-full max-w-sm rounded p-8 bg-gray-100 shadow-2xl`;

const FieldContainer = tw.div`md:flex md:items-center mb-6`;
const LabelContainer = tw.div`md:w-1/3`;
const Label = tw.label`block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4`;
const InputContainer = tw.div`md:w-2/3`;
const Input = tw.input`bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500`;
const Button = tw.button`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-center`;
const HOne = tw.h1`block sm:text-xl md:text-2xl lg:text-4xl font-medium md:w-1/2 md:pr-10 md:my-0 my-8`;
const ErrorText = tw.div`text-red-400`;
const ErrorMessage = tw.div``;
const SuccessMessage = tw.div``;

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// markup
const IndexPage = () => {
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phone: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Required"),
      lastName: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("Required"),
      phone: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      console.log("hey oh", values);

      axios
        .post(`${process.env.GATSBY_API_URI}/subscribers`, {
          first_name: values.firstName,
          last_name: values.lastName,
          phone_number: values.phone,
        })
        .then((x) => {
          setIsSuccess(true);
          resetForm();
        })
        .catch(() => setIsError(true));
    },
  });

  return (
    <Page>
      <title>Mama Well Text Message Sign up</title>
      <img style={{ margin: "0 auto" }} src={"/tmw_header.png"} alt="Logo" />
      <PageContainer>
        <HOne style={{ color: "#e7c0b6" }}>
          Sign up to receive text messages from the Mama Well
        </HOne>
        <Form onSubmit={formik.handleSubmit}>
          <FieldContainer>
            <LabelContainer>
              <Label htmlFor="firstName">First Name</Label>
            </LabelContainer>
            <InputContainer>
              <Input
                id="firstName"
                name="firstName"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
              />
              {formik.touched.firstName && formik.errors.firstName ? (
                <ErrorText>{formik.errors.firstName}</ErrorText>
              ) : null}
            </InputContainer>
          </FieldContainer>
          <FieldContainer>
            <LabelContainer>
              <Label htmlFor="lastName">Last Name</Label>
            </LabelContainer>
            <InputContainer>
              <Input
                id="lastName"
                name="lastName"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
              />
              {formik.touched.lastName && formik.errors.lastName ? (
                <ErrorText>{formik.errors.lastName}</ErrorText>
              ) : null}
            </InputContainer>
          </FieldContainer>
          <FieldContainer>
            <LabelContainer>
              <Label htmlFor="phone">Phone #</Label>
            </LabelContainer>
            <InputContainer>
              <Input
                id="phone"
                name="phone"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
              />
              {formik.touched.phone && formik.errors.phone ? (
                <ErrorText>{formik.errors.phone}</ErrorText>
              ) : null}
            </InputContainer>
          </FieldContainer>
          <Button type="submit">Submit</Button>
          {isError && (
            <ErrorMessage>We we're unable to sign you up</ErrorMessage>
          )}
          {isSuccess && (
            <SuccessMessage>We've receieve your request!</SuccessMessage>
          )}
        </Form>
      </PageContainer>
    </Page>
  );
};

export default IndexPage;
